import { mapKeys, snakeCase, camelCase } from "lodash"
import { DateTime } from "luxon"

const BASE_URL = process.env.PREPR_API_BASE_URL
export const DATE_FORMAT = "yyyy-LL-dd"

function endpoint(endpoint) {
  return `${BASE_URL}${endpoint}`
}

export function fromDateFormat(dateString) {
  if (typeof dateString === "undefined") {
    return undefined
  }

  return DateTime.fromFormat(dateString, DATE_FORMAT)
}

export function convertToDateFormat(dateString, initialFormat) {
  if (typeof dateString === "undefined") {
    return undefined
  }

  return DateTime.fromFormat(dateString, initialFormat).toFormat(DATE_FORMAT)
}

export function transformRequest(request) {
  return mapKeys(request, (value, key) => snakeCase(key))
}

export function transformResponse(response) {
  return mapKeys(response, (value, key) => camelCase(key))
}

export const getPublicationsEndpoint = obj => endpoint(`publications?${obj}`)
export const getVoteTracksEndpoint = obj => endpoint(`publications?${obj}`)
export const getPersonalVotesEndpoint = obj =>
  endpoint(`persons/me/votes?${obj}`)
export const voteItemByIdEndpoint = id => endpoint(`publications/${id}/vote`)
export const deleteVoteEndpoint = id => endpoint(`persons/me/votes/${id}`)
export const getCompetitionRuleEndpoint = (id, obj) =>
  endpoint(`/publications/${id}?${obj}`)
export const getPublicationByIdEndpoint = (id, obj) => endpoint(`publications/${id}?${obj}`)
