import { mapKeys, snakeCase, camelCase } from "lodash"

const BASE_URL = process.env.API_BASE_URL

function endpoint(endpoint) {
  return `${BASE_URL}${endpoint}`
}

export function transformRequest(request) {
  return mapKeys(request, (value, key) => snakeCase(key))
}

export function transformResponse(response) {
  return mapKeys(response, (value, key) => camelCase(key))
}

export const getRadioTracksEndpoint = radioId =>
  endpoint(`api/v1/radio/${radioId}/tracks`)
export const getPlaylistEndpoint = (radioId, obj) =>
  endpoint(
    `api/v1/radio/${radioId}/tracks-paginated?page=${obj.current_page}&per_page=${obj.per_page}`
  )
export const removeTermEndpoint = (personId, id) =>
  endpoint(`api/v1/delete/nostalgie/term/${personId}/${id}`)

export const generateQualifioTokenEndpoint = endpoint(`api/v1/nostalgie/qualifio/token`)
